<script>
import Layout from "../../../layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import axios from 'axios'

export default {
  page: {
    title: "Add Port",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Add Port",
      items: [
        {
          text: "Master Data"
        },
        {
          text: "Port",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],
      info: null,
      status: "not_accepted",
      checkCustom: "not_accepted",
      checked: true,
      value_timezone: null,
      options_timezone: [
        "WIB",
        "WITA",
        "WIT"
      ]
    };
  }/*,
  mounted () {
    axios
      .get('https://api.coindesk.com/v1/bpi/currentprice.json')
      .then(response => (this.info = response))
  } {{ info }}*/
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Name"
                    label-for="port_name"
                  >
                    <b-form-input id="port_name" name="port_name" value="Soekarno–Hatta"></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Initial"
                    label-for="port_initial"
                  >
                    <b-form-input id="port_initial" name="port_initial" value="CGK"></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Address"
                    label-for="port_address"
                  >
                    <b-form-input id="port_address" name="port_address" value="Jakarta"></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Time Zone"
                    label-for="port_address"
                  >
                    <multiselect v-model="value_timezone" :options="options_timezone"></multiselect>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="UTC"
                    label-for="port_address"
                  >
                    <b-form-input id="port_address2" name="port_address2" value="7"></b-form-input>
                  </b-form-group>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
   
   
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>